import React from "react"
import PropTypes from "prop-types"
import ExcluPage from "../containers/excluPage"
import doublodesigngif from "../images/doublo-design.gif"

const CadreEntreDeuxVerres = (props) => {
  return (
    <>
      <ExcluPage
        title="Cadre doublo design"
        description={
          <>
            Ce cadre doublo design permet de donner beaucoup + d’éclat au sujet encadré.
            <br />
            Idéal pour la mise en valeur de vos aquarelles, photos, sérigraphies, dessins. Pourra aussi convenir pour encadrer des cartons toilés ou des toiles
            sur châssis, etc.
            <br />
            La couleur du cadre intérieur est proposée dans des finitions multiples : laquée ou satinée ou encore intemporel (or , argent, etc.).
          </>
        }
        link="/cadres/Cadre-doublo-design/"
        img={doublodesigngif}
        location={props.location}
      />
    </>
  )
}

CadreEntreDeuxVerres.propTypes = {
  identifiants: PropTypes.object,
  location: PropTypes.object,
}

export default CadreEntreDeuxVerres
